import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import {
    ResponseAddZipCode,
    ResponseFollowersProfile,
    ResponseInfoUserNotActivation,
    ResponseMyFollowers,
    ResponseNearMe,
    ResponseUserProfile,
    ResponseUserProfilePublic,
    ResponseUserProfileView,
    ResponseViewBarter,
} from '@app/models/profile/response-profile.model';
import { DtoUpdateUser } from '@app/models/profile/dto-update-user.model';
import { DtoUpdateInformationSensitive } from '@app/models/profile/dto-update-information-sensitive.model';
import { DtoUpdatePassword } from '@app/models/profile/dto-update-password.model';
import { DtoFollowersProfile } from '@app/models/profile/dto-followers-profile.mode';
import { DtoReportUser } from '@app/models/profile/dto-report-user.model';
import { DtoUnLockUser } from '@app/models/profile/dto-unLock-user.model';
import { DtoLockUser } from '@app/models/profile/dto-lock-user.model';
import { DtoUserMap } from '@app/models/profile/dto-user-map.model';
import { DtoViewBarter } from '@app/models/profile/dto-view-barter.model';

@Injectable({
    providedIn: 'root',
})
export class ProfileService {
    private readonly API_URI_V1 = environment.PROXY
        ? '/api/'
        : environment.API_URL_V1;

    private readonly API_URI_V2 = environment.PROXY
        ? '/api/v2/'
        : environment.API_URL_V2;
    private http = inject(HttpClient);

    userProfile(): Observable<ResponseUserProfile> {
        return this.http.get<ResponseUserProfile>(
            `${this.API_URI_V1}user/profile`
        );
    }

    userProfileView(id: string): Observable<ResponseUserProfileView> {
        return this.http.get<ResponseUserProfileView>(
            `${this.API_URI_V1}user/profile/${id}`
        );
    }

    userProfilePublic(id: string): Observable<ResponseUserProfilePublic> {
        return this.http.get<ResponseUserProfilePublic>(
            `${this.API_URI_V1}user/profile/public/${id}`
        );
    }

    updateUser(dto: DtoUpdateUser): Observable<void> {
        return this.http.put<void>(`${this.API_URI_V1}user`, dto);
    }

    updateInformationSensitive(
        dto: DtoUpdateInformationSensitive
    ): Observable<void> {
        return this.http.put<void>(
            `${this.API_URI_V1}user/update/information/sensitive`,
            dto
        );
    }

    sentLike(userId: string): Observable<void> {
        return this.http.put<void>(`${this.API_URI_V1}user/likeProfile`, {
            userId,
        });
    }

    sentShare(_id: string): Observable<void> {
        return this.http.put<void>(`${this.API_URI_V1}user/numberShares`, {
            _id,
        });
    }

    updatePassword(dto: DtoUpdatePassword): Observable<void> {
        return this.http.put<void>(
            `${this.API_URI_V1}user/update/password`,
            dto
        );
    }

    myFollowers(): Observable<ResponseMyFollowers> {
        return this.http.get<ResponseMyFollowers>(
            `${this.API_URI_V1}user/myFollowers`
        );
    }

    sentFollower(userId: string): Observable<void> {
        return this.http.put<void>(`${this.API_URI_V1}user/follower`, {
            userId,
        });
    }

    followersProfile(
        dto: DtoFollowersProfile
    ): Observable<ResponseFollowersProfile> {
        let params = new HttpParams();
        params = params.append('userId', dto.userId);
        params = params.append('skip', dto.skip);
        params = params.append('limit', dto.limit);

        return this.http.get<ResponseFollowersProfile>(
            `${this.API_URI_V1}user/followers`,
            {
                params,
            }
        );
    }

    userMap(dto: DtoUserMap): Observable<any> {
        let params = new HttpParams();
        params = params.append('latitude', dto.latitude);
        params = params.append('longitude', dto.longitude);
        params = params.append('maxDistance', dto.maxDistance);

        return this.http.get<any>(`${this.API_URI_V1}user/maps`, {
            params,
        });
    }

    blockUser(dto: DtoLockUser): Observable<void> {
        return this.http.post<void>(
            `${this.API_URI_V1}user-blocked/block`,
            dto
        );
    }

    unLockUser(dto: DtoUnLockUser): Observable<void> {
        return this.http.post<void>(
            `${this.API_URI_V1}user-blocked/unlock`,
            dto
        );
    }

    reportUser(dto: DtoReportUser): Observable<void> {
        return this.http.post<void>(`${this.API_URI_V1}user/report-user`, dto);
    }

    viewBarter(dto: DtoViewBarter): Observable<ResponseViewBarter> {
        let params = new HttpParams();
        params = params.append('typeMatch', dto.typeMatch);
        params = params.append('typeBarter', dto.typeBarter);
        params = params.append('skip', dto.skip);
        params = params.append('limit', dto.limit);

        return this.http.get<ResponseViewBarter>(`${this.API_URI_V1}barter`, {
            params,
        });
    }

    getPostMyBox(): Observable<any> {
        return this.http.get<any>(`${this.API_URI_V1}post-box`, {});
    }

    infoUserNotActivation(
        token: string
    ): Observable<ResponseInfoUserNotActivation> {
        return this.http.get<ResponseInfoUserNotActivation>(
            `${this.API_URI_V2}user/info-user-not-activation`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
    }

    nearMe(
        dto: {
            distance: number;
            latitude: number | string;
            longitude: number | string;
        },
        token: string
    ): Observable<ResponseNearMe> {
        let params = new HttpParams();
        params = params.append('distance', dto.distance);
        params = params.append('latitude', dto.latitude);
        params = params.append('longitude', dto.longitude);

        return this.http.get<ResponseNearMe>(`${this.API_URI_V2}user/near-me`, {
            params,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    addLatLng(
        latitude: string,
        longitude: string,
        token: string
    ): Observable<void> {
        return this.http.patch<void>(
            `${this.API_URI_V2}user/add-lat-lng`,
            { latitude, longitude },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
    }

    addZipCode(
        postalCodeId: string,
        token: string
    ): Observable<ResponseAddZipCode> {
        return this.http.patch<ResponseAddZipCode>(
            `${this.API_URI_V2}user/add-zip-code`,
            { postalCodeId },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
    }

    addPicture(data: FormData, token: string): Observable<void> {
        return this.http.put<void>(`${this.API_URI_V2}user/picture`, data, {
            headers: {
                'Cache-Control': 'no-cache',
                Authorization: `Bearer ${token}`,
            },
        });
        // .pipe(retry(2));
    }

    contactSupport(dto: {
        typeReport: string;
        message: string;
        email?: string;
    }): Observable<void> {
        return this.http.post<void>(
            `${this.API_URI_V1}contact-support`,
            dto,
            {}
        );
        // .pipe(retry(2));
    }
}
